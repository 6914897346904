.new {
    width: 100%;
    display: flex;
    .newContainer {
        flex: 6;
        .top,
        .bottom {
            padding: 10px;
            box-shadow: 5px 5px 5px 1px rgba(0, 0, 0, 0.23);
            -webkit-box-shadow: 5px 5px 5px 1px rgba(0, 0, 0, 0.23);
            -moz-box-shadow: 5px 5px 5px 1px rgba(0, 0, 0, 0.23);
            border-radius: 10px;
            margin: 20px;
            display: flex;
            h1 {
                color: lightgray;
                font-size: 20px;
            }
            .left {
                flex: 1;
                text-align: center;
                img {
                    width: 100px;
                    height: 100px;
                    border-radius: 50%;
                    object-fit: cover;
                }
            }
            .right {
                flex: 2;
                form {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 20px;
                    justify-content: space-around;
                    label {
                        display: flex;
                        align-items: center;
                        gap: 30px;
                        .icon {
                            cursor: pointer;
                        }
                    }
                    .formInput {
                        width: 40%;
                        input {
                            width: 100%;
                            padding: 5px;
                            border: none;
                            border-bottom: 1px solid gray;
                        }
                    }
                    button {
                        width: 150px;
                        padding: 10px;
                        border: none;
                        background-color: teal;
                        color: white;
                        font-weight: bold;
                        cursor: pointer;
                        margin-top: 10px;
                        &:disabled {
                            background-color: rgba(2, 99, 99, 0.304);
                            cursor: not-allowed;
                        }
                    }
                }
            }
        }
    }
}