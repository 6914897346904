.widget {
    display: flex;
    justify-content: space-between;
    flex: 1;
    padding: 10px;
    box-shadow: 5px 5px 5px 1px rgba(0, 0, 0, 0.23);
    -webkit-box-shadow: 5px 5px 5px 1px rgba(0, 0, 0, 0.23);
    -moz-box-shadow: 5px 5px 5px 1px rgba(0, 0, 0, 0.23);
    border-radius: 10px;
    height: 100px;
    .left,
    .right {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .title {
            font-weight: bold;
            font-size: 14px;
            color: rgb(163, 158, 158)
        }
        .counter {
            font-size: 28px;
            font-weight: 300;
        }
        .link {
            width: max-content;
            font-size: 12px;
            border-bottom: 1px solid gray;
            cursor: pointer;
        }
        .percentage {
            display: flex;
            align-items: center;
            font-size: 14px;
            cursor: pointer;
            &.positive {
                color: green;
            }
            &.negative {
                color: red;
            }
        }
        .icon {
            font-size: 18px;
            padding: 5px;
            border-radius: 5px;
            align-self: flex-end;
            cursor: pointer;
        }
    }
}