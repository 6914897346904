.chart {
    flex: 4;
    box-shadow: 5px 5px 5px 1px rgba(0, 0, 0, 0.23);
    -webkit-box-shadow: 5px 5px 5px 1px rgba(0, 0, 0, 0.23);
    -moz-box-shadow: 5px 5px 5px 1px rgba(0, 0, 0, 0.23);
    border-radius: 10px;
    padding: 10px;
    color: gray;
    .title {
        margin-bottom: 20px;
    }
    .chartGrid {
        stroke: rgb(228, 225, 225);
    }
}