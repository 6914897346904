.home {
    display: flex;
    .homeContainer {
        flex: 6;
        .widgets,
        .charts {
            display: flex;
            padding: 20px;
            gap: 20px;
        }
        .charts {
            padding: 5px 20px;
        }
        .listContainer {
            box-shadow: 5px 5px 5px 1px rgba(0, 0, 0, 0.23);
            -webkit-box-shadow: 5px 5px 5px 1px rgba(0, 0, 0, 0.23);
            -moz-box-shadow: 5px 5px 5px 1px rgba(0, 0, 0, 0.23);
            border-radius: 10px;
            padding: 20px;
            margin: 20px;
            .listTitle {
                font-weight: 500;
                color: gray;
                margin-bottom: 15px;
            }
        }
    }
}